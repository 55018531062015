.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.main-container {
  display: flex;
  width: 100%;
  justify-content: space-around;

  @media screen and (max-width: 750px) {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - 45px);
  }
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 710px;

  & > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;

    color: rgba(26, 26, 26, 0.6);
    margin-bottom: 60px;
  }

  & > img {
    width: 150px;
  }

 .icons-container {
   display: flex;

   & > img {
     margin: 0 16px;
     width: 60px;

     @media screen and (max-width: 750px) {
       width: 40px;
      margin: 0 10px;
     }
   }
 }
}
